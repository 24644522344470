import { withPrefix } from "gatsby";
import Cookies from "js-cookie";
import { nanoid } from "nanoid";
import { getCartId, removeCartId, saveCartId } from "./cookies";

const BASE_URL = `/commerce-cloud-api`;
const SITE_ID = process.env.GATSBY_COMMERCE_CLOUD_SITE_ID;
const CLIENT_ID = process.env.GATSBY_COMMERCE_CLOUD_CLIENT_ID;
const ORGANIZATION_ID = process.env.GATSBY_COMMERCE_CLOUD_ORGANIZATION_ID;
const IDP_SLAS_NAME = process.env.GATSBY_COMMERCE_CLOUD_IDP_SLAS_NAME;
const GUEST_REDIRECT_URI = process.env.GATSBY_COMMERCE_CLOUD_GUEST_REDIRECT_URI;
const USER_REDIRECT_URI = process.env.GATSBY_COMMERCE_CLOUD_USER_REDIRECT_URI;
const COMMERCE_CLOUD_URL = process.env.GATSBY_COMMERCE_CLOUD_URL;
const COMMERCE_CLOUD_ACCOUNT_URL = process.env.GATSBY_COMMERCE_CLOUD_ACCOUNT_URL;

const getGuestRedirectUri = () =>
  GUEST_REDIRECT_URI ? new URL(GUEST_REDIRECT_URI, window.location.origin).href : null;
const getUserRedirectUri = () =>
  USER_REDIRECT_URI ? new URL(USER_REDIRECT_URI, window.location.origin).href : null;

export const hasCommerceCloud = !!SITE_ID && !!CLIENT_ID;

export const VERIFIER_KEY = "cc-verifier";
export const PATHNAME_KEY = "cc-pathname";
export const STATE_KEY = "cc-state";

const generateCodeVerifier = () => nanoid(128);
const sha256 = async (string) => {
  const msgUint8 = new TextEncoder().encode(string); // encode as (utf-8) Uint8Array
  return crypto.subtle.digest("SHA-256", msgUint8);
};
const urlSafe = (input) => input.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
const base64Url = (buffer) => {
  const base64Digest = window.btoa(String.fromCharCode(...new Uint8Array(buffer)));
  return urlSafe(base64Digest);
};
const generateCodeChallenge = async (verifier) => base64Url(await sha256(verifier));

const getLoginUrl = async (verifier, state) => {
  const code_challenge = await generateCodeChallenge(verifier);
  const params = new URLSearchParams({
    channel_id: SITE_ID,
    redirect_uri: getUserRedirectUri(),
    client_id: CLIENT_ID,
    code_challenge,
    state,
    usid: Cookies.get("usid"),
    hint: IDP_SLAS_NAME,
    response_type: "code",
  });
  return `${COMMERCE_CLOUD_URL}/shopper/auth/v1/organizations/${ORGANIZATION_ID}/oauth2/authorize?${params}`;
};

let initedCC = false;
let initPromise;
const initCommerceCloud = async (force = false) => {
  console.log("initCommerceCloud");
  if (initedCC && !force) {
    console.log("CommerceCloud already inited");
    return;
  }
  if (!initPromise || force) {
    const internalInit = async () => {
      if (await checkValidGuestSession()) {
        await initGuestCart();
        return;
      }

      await startGuestLogin();
      await initGuestCart();
      await initOcapiSession();
      console.log("initCommerceCloud completed");
      initedCC = true;
    };
    initPromise = internalInit();
    initPromise.then((data) =>
      document.dispatchEvent(new CustomEvent("commerce-cloud-init", { detail: data }))
    );
    initPromise.finally(() => {
      initPromise = null;
    });
  }
  return new Promise((resolve, reject) => {
    const listener = (e) => resolve(e.detail);
    document.addEventListener("commerce-cloud-init", listener);
    initPromise
      .then(resolve)
      .catch(reject)
      .finally(() => {
        document.removeEventListener("commerce-cloud-init", listener);
      });
  });
};

export const startUserLogin = async () => {
  await initCommerceCloud();
  const verifier = generateCodeVerifier();
  window.sessionStorage.setItem(VERIFIER_KEY, verifier);
  window.sessionStorage.setItem(PATHNAME_KEY, window.location.pathname);
  const state = nanoid(24);
  window.sessionStorage.setItem(STATE_KEY, state);
  const loginUrl = await getLoginUrl(verifier, state);
  window.location.href = loginUrl;
};

const fetchToken = async (bodyParams) => {
  const params = new URLSearchParams({
    siteId: SITE_ID,
  });
  const tokenUrl = `${BASE_URL}/shopper/auth/v1/organizations/${ORGANIZATION_ID}/oauth2/token?${params.toString()}`;
  const tokenRes = await fetch(tokenUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(bodyParams),
  });
  if (!tokenRes.ok) {
    try {
      const { status_code, message } = await tokenRes.json();
      console.error("Error: ", tokenRes.status, tokenRes.statusText);
      const status = status_code || tokenRes.status;
      const statusText = message || tokenRes.statusText;
      throw new Error(`Error ${status} ${statusText} - calling ${tokenUrl}`);
    } catch (err) {
      console.warn("Cannot read error data: ", err);
    }
    throw new Error(`Error ${tokenRes.status} ${tokenRes.statusText} - calling ${tokenUrl}`);
  }
  return tokenRes.json();
};

export const getToken = async (code, verifier, auth = false) => {
  return fetchToken({
    code,
    grant_type: "authorization_code_pkce",
    redirect_uri: auth ? getUserRedirectUri() : getGuestRedirectUri(),
    code_verifier: verifier,
    channel_id: SITE_ID,
    client_id: CLIENT_ID,
  });
};

const refreshToken = async () => {
  const refresh_token = Cookies.get("cc-nx") || Cookies.get("cc-nx-g");
  if (!refresh_token) {
    console.warn("Cannot refresh, should init guest session");
    return initCommerceCloud(true);
  }
  console.log("refresh");
  try {
    const data = await fetchToken(
      {
        grant_type: "refresh_token",
        refresh_token: refresh_token,
        client_id: CLIENT_ID,
      },
      SITE_ID
    );
    setUserCookies(data, !!Cookies.get("cc-nx"));
  } catch (err) {
    console.error("Refresh error: ", err);
    console.log("Cannot refresh due to error, cleanup cookies and setup guest session");
    Cookies.remove("usid");
    Cookies.remove("cid");
    Cookies.remove("cid-g");
    Cookies.remove("token");
    Cookies.remove("token-g");
    Cookies.remove("cc-nx");
    Cookies.remove("cc-nx-g");
    Cookies.remove("token-match-g");
    initedCC = false;
    return initCommerceCloud(true);
  }
};

export const setUserCookies = (data, auth = false) => {
  Cookies.set(auth ? "token" : "token-g", data.access_token, {
    expires: new Date(Date.now() + data.expires_in * 1000),
  });
  Cookies.set(auth ? "cc-nx" : "cc-nx-g", data.refresh_token, {
    expires: new Date(Date.now() + data.refresh_token_expires_in * 1000),
  });
  Cookies.set("usid", data.usid, {
    expires: new Date(Date.now() + data.refresh_token_expires_in * 1000),
  });
  Cookies.set(auth ? "cid" : "cid-g", data.customer_id, {
    expires: new Date(Date.now() + data.refresh_token_expires_in * 1000),
  });
  if (!auth) {
    Cookies.set("token-match-g", `${data.refresh_token}-${data.access_token}`, {
      expires: new Date(Date.now() + data.expires_in * 1000),
    });
  }
  if (auth) {
    Cookies.remove("cc-nx-g");
    Cookies.remove("token-g");
    Cookies.remove("cid-g");
    Cookies.remove("token-match-g");
  }
};

export const getAccountUrl = () => `${COMMERCE_CLOUD_ACCOUNT_URL}/comm-my-account`;

export const getCommunityUrl = () => COMMERCE_CLOUD_ACCOUNT_URL;

export const getOrdersUrl = () => `${COMMERCE_CLOUD_ACCOUNT_URL}/my-orders`;

const isUserLogged = () => Cookies.get("cc-nx");

const loadUserData = async () => {
  if (!isUserLogged()) return null;
  try {
    const customerId = Cookies.get("cid");
    if (!customerId) {
      console.warn("Missing customerId cookie");
      return null;
    }
    const params = new URLSearchParams({
      siteId: SITE_ID,
    });
    const customerUrl = `${BASE_URL}/customer/shopper-customers/v1/organizations/${ORGANIZATION_ID}/customers/${customerId}?${params}`;
    const res = await fetchAuth(customerUrl);
    if (!res.ok) {
      throw new Error(`Error getting user data ${res.status} ${res.statusText}`);
    }
    const userData = await res.json();
    return {
      given_name: userData.firstName,
      family_name: userData.lastName,
      email: userData.email,
      customerId: userData.customerId,
      userId: userData.login?.split("-")?.reverse()?.[0],
    };
  } catch (err) {
    console.error("loadUserData err: ", err);
  }
};

let userDataPromise;
export const getUserData = async () => {
  if (!isUserLogged()) return null;
  if (!userDataPromise) {
    userDataPromise = loadUserData();
    userDataPromise.finally(() => {
      userDataPromise = null;
    });
  }
  return userDataPromise;
};

let guestCart;
const initGuestCart = async () => {
  console.log("init guest cart");
  if (guestCart) {
    return guestCart;
  }
  const existingCartId = getCartId();
  guestCart = await loadBasket(existingCartId);
  if (!guestCart) {
    const cartId = await createBasket();
    saveCartId(cartId);
    return guestCart;
  }
  saveCartId(guestCart.id);
  return guestCart;
};

export const userLogout = async () => {
  const refresh_token = Cookies.get("cc-nx");
  if (!refresh_token) return;
  const authorization = await getAuthHeader();
  const params = new URLSearchParams({
    client_id: CLIENT_ID,
    refresh_token,
  });
  const logoutUrl = `${BASE_URL}/shopper/auth/v1/organizations/${ORGANIZATION_ID}/oauth2/logout?${params}`;
  const res = await fetch(logoutUrl, {
    headers: {
      authorization,
    },
  });
  if (!res.ok) {
    throw new Error(`Error logout ${res.status} ${res.statusText}`);
  }
  Cookies.remove("usid");
  Cookies.remove("cid");
  Cookies.remove("cid-g");
  Cookies.remove("token");
  Cookies.remove("token-g");
  Cookies.remove("cc-nx");
  Cookies.remove("cc-nx-g");
  Cookies.remove("token-match-g");
  removeCartId();
  guestCart = null;
  await initCommerceCloud();
};

export const createOcapiSession = async () => {
  // const authorization = await getAuthHeader();
  const res = await fetchAuth(`/s/${SITE_ID}/dw/shop/v23_2/sessions`, {
    method: "POST",
  });
  if (!res.ok) {
    console.log("err: ", await res.json());
    throw new Error(`Error init session ${res.status} ${res.statusText}`);
  }
  console.log("Ocapi session created");
};

let ocapiSessionPromise;
const initOcapiSession = async () => {
  if (!ocapiSessionPromise) {
    ocapiSessionPromise = createOcapiSession();
    ocapiSessionPromise.finally(() => {
      ocapiSessionPromise = null;
    });
  }
  return ocapiSessionPromise;
};

const cookiesGuestMatch = () => {
  const match = Cookies.get("token-match-g");
  const token = Cookies.get("token-g");
  const refresh = Cookies.get("cc-nx-g");
  return match === `${refresh}-${token}`;
};

const startGuestLogin = async () => {
  console.log("Start guest login");
  const verifier = generateCodeVerifier();
  const code_challenge = await generateCodeChallenge(verifier);
  const params = new URLSearchParams({
    siteId: SITE_ID,
    redirect_uri: getGuestRedirectUri(),
    client_id: CLIENT_ID,
    code_challenge,
    hint: "guest",
  });

  const response = await fetch(
    `${BASE_URL}/shopper/auth/v1/organizations/${ORGANIZATION_ID}/oauth2/authorize?${params}`
  );
  if (!response.ok) {
    throw new Error(`Error in authorize callback ${response.status} ${response.statusText}`);
  }
  const redirectParams = new URL(response.url).searchParams;
  const code = redirectParams.get("code");

  const data = await getToken(code, verifier);
  setUserCookies(data, false);
  console.log("Guest login success");
};

const fetchAuth = async (url, options = {}, force) => {
  const authorization = await getAuthHeader(force);
  const res = await fetch(url, {
    ...options,
    headers: {
      ...options?.headers,
      authorization,
    },
  });
  if (!res.ok && (res.status === 400 || res.status === 401)) {
    Cookies.remove("token");
    Cookies.remove("token-g");
    const authorization = await getAuthHeader(force);
    return fetch(url, {
      ...options,
      headers: {
        ...options?.headers,
        authorization,
      },
    });
  }
  return res;
};

const requestAccessToken = async (force) => {
  console.log("requestAccessToken");
  let auth_token;
  if (isUserLogged()) {
    auth_token = Cookies.get("token");
  } else {
    await checkValidGuestSession();
    auth_token = Cookies.get("token-g");
  }
  if (!auth_token) {
    if (!isUserLogged()) {
      if (!force) {
        await initCommerceCloud();
      }
      auth_token = Cookies.get("token-g");
      if (!auth_token) {
        await requestRefreshToken();
        auth_token = Cookies.get("token-g");
      }
    } else {
      await requestRefreshToken();
      auth_token = Cookies.get("token") || Cookies.get("token-g");
    }
  }
  if (!auth_token) {
    console.error("FATAL - Could not get auth token");
  }
  console.log("auth token ok");
  return auth_token;
};

const checkValidGuestSession = async () => {
  if (Cookies.get("cc-nx-g")) {
    // Refresh token ok => the session is valid
    if (Cookies.get("token-g") && !cookiesGuestMatch()) {
      // There is an outdated authorization token => clean up, it will be regenerated
      console.warn("Outdated guest cookies- cleanup");
      Cookies.remove("token-g");
      Cookies.remove("token-match-g");
      Cookies.remove("cid-g");
      removeCartId();
      await requestRefreshToken();
    }
    console.info("Valid guest session");
    return true;
  }
  return false;
};

const getAuthHeader = async (force) => {
  const auth_token = await requestAccessToken(force);
  return `Bearer ${auth_token}`;
};

let refreshPromise;
const requestRefreshToken = async () => {
  if (!refreshPromise) {
    refreshPromise = refreshToken();
    refreshPromise.finally(() => {
      refreshPromise = null;
    });
  }
  await refreshPromise;
  const auth_token = isUserLogged() ? Cookies.get("token") : Cookies.get("token-g");
  return auth_token;
};

const normalizeCart = (basket) => {
  return {
    ...basket,
    id: basket.basketId,
    items: basket?.productItems?.filter(Boolean)?.map((item) => ({
      ...item,
    })),
  };
};

export const createBasket = async () => {
  if (guestCart) {
    return guestCart.id;
  }
  console.log("createBasket");
  // const authorization = await getAuthHeader();
  const params = new URLSearchParams({
    siteId: SITE_ID,
  });
  const basketUrl = `${BASE_URL}/checkout/shopper-baskets/v1/organizations/${ORGANIZATION_ID}/baskets?${params.toString()}`;
  const res = await fetchAuth(basketUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });
  if (!res.ok) {
    try {
      const data = await res.json();
      console.error("Error: ", data);
    } catch (err) {
      console.warn("Cannot read error data: ", err);
    }
    throw new Error(`Error create basket ${res.status} ${res.statusText}`);
  }
  const data = await res.json();
  guestCart = normalizeCart(data);
  return guestCart.id;
};

const fetchBasketWithAutoRetry = async () => {
  const params = new URLSearchParams({
    siteId: SITE_ID,
  });
  let customer_id = isUserLogged() ? Cookies.get("cid") : Cookies.get("cid-g");
  if (!customer_id) {
    console.error("Missing customer id, refresh");
    await requestRefreshToken();
    customer_id = isUserLogged() ? Cookies.get("cid") : Cookies.get("cid-g");
    if (!customer_id) {
      throw new Error("Missing customer id");
    }
  }
  const getBasketUrl = `${BASE_URL}/customer/shopper-customers/v1/organizations/${ORGANIZATION_ID}/customers/${customer_id}/baskets?${params.toString()}`;
  const authorization = await getAuthHeader(true);
  const res = await fetch(getBasketUrl, {
    headers: {
      authorization,
    },
  });
  if (!res.ok && (res.status === 400 || res.status === 401)) {
    Cookies.remove("token");
    Cookies.remove("token-g");
    await requestRefreshToken();
    const customer_id = isUserLogged() ? Cookies.get("cid") : Cookies.get("cid-g");
    if (!customer_id) {
      console.error("Missing customer id");
      return null;
    }
    const getBasketUrl = `${BASE_URL}/customer/shopper-customers/v1/organizations/${ORGANIZATION_ID}/customers/${customer_id}/baskets?${params.toString()}`;
    const authorization = await getAuthHeader(true);
    return fetch(getBasketUrl, {
      headers: {
        authorization,
      },
    });
  }
  return res;
};

const loadBasket = async (basketId) => {
  console.log("loadBasket");
  if (!(await checkValidGuestSession())) {
    throw new Error("Invalid session");
  }
  const res = await fetchBasketWithAutoRetry();
  if (!res.ok) {
    throw new Error(`Error get basket ${res.status} ${res.statusText}`);
  }
  const data = await res.json();
  let basket;
  if (basketId) {
    basket = data.baskets?.find((item) => item.basketId === basketId);
    if (!basket) {
      console.warn(`Cannot find basket with id ${basketId} for current customer, taking first one`);
      basket = data.baskets?.[0];
      if (!basket) {
        console.warn(`Cannot find basket for current customer`);
        return null;
      }
    }
  } else {
    basket = data.baskets?.[0];
    if (!basket) {
      console.warn(`Cannot find basket for current customer`);
      return null;
    }
  }
  return normalizeCart(basket);
};

export const getBasket = async () => {
  console.log("getBasket");
  await initCommerceCloud();
  return guestCart;
};

export const addProductToBasket = async (productId, basketId) => {
  // const authorization = await getAuthHeader();
  const params = new URLSearchParams({
    siteId: SITE_ID,
  });
  const addToBasket = `${BASE_URL}/checkout/shopper-baskets/v1/organizations/${ORGANIZATION_ID}/baskets/${basketId}/items?${params.toString()}`;
  const res = await fetchAuth(addToBasket, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify([
      {
        productId: productId,
        quantity: 1,
      },
    ]),
  });
  if (!res.ok) {
    try {
      const data = await res.json();
      console.error("Error: ", data);
    } catch (err) {
      console.warn("Cannot read error data: ", err);
    }
    throw new Error(`Error add to basket ${res.status} ${res.statusText}`);
  }
  const data = await res.json();
  return { cart: normalizeCart(data) };
};

const normalizeProduct = (ccProduct) => {
  const ccListPrice =
    ccProduct.tieredPrices?.find(
      (tieredPrice) =>
        /haier-[a-z]{2}-list-prices/.test(tieredPrice?.pricebook) && tieredPrice?.price
    ) && ccProduct.currency
      ? {
          value: ccProduct.tieredPrices?.find(
            (tieredPrice) =>
              /haier-[a-z]{2}-list-prices/.test(tieredPrice?.pricebook) && tieredPrice?.price
          )?.price,
          currency: ccProduct.currency,
        }
      : null;
  const ccSpecialPrice =
    ccProduct.tieredPrices?.find(
      (tieredPrice) =>
        /haier-[a-z]{2}-special-prices/.test(tieredPrice?.pricebook) && tieredPrice?.price
    ) && ccProduct.currency
      ? {
          value: ccProduct.tieredPrices?.find(
            (tieredPrice) =>
              /haier-[a-z]{2}-special-prices/.test(tieredPrice?.pricebook) && tieredPrice?.price
          )?.price,
          currency: ccProduct.currency,
        }
      : null;
  const ccOmnibusPrice =
    ccProduct?.c_omnibusPrice && Number(ccProduct?.c_omnibusPrice) !== ccProduct?.price
      ? Number(ccProduct?.c_omnibusPrice)
      : null;

  return {
    id: ccProduct.id,
    sku: ccProduct.id,
    name: ccProduct.name,
    descriptionHtml: ccProduct.longDescription,
    availableForSale: !!ccProduct.price && !!ccProduct.currency && !!ccProduct.inventory?.orderable,
    sellingPrice: ccSpecialPrice ? ccSpecialPrice : ccListPrice, // SELLING PRICE
    omnibusPrice: ccOmnibusPrice ? ccListPrice : null, // OMNIBUS PRICE
    msrpPrice: ccOmnibusPrice
      ? { value: ccOmnibusPrice, currency: ccProduct.currency }
      : ccListPrice, // MSRP
    extra_services: ccProduct.options?.map((item) => {
      const value = item?.values?.find((value) => value?.id !== "noservice");
      return {
        descriptionHtml: item?.description,
        name: item?.name,
        sku: value?.id,
        default: value?.default,
        msrpPrice: {
          value: value?.price,
          currency: ccProduct.currency,
        },
      };
    }),
    // special_from_date: ccProduct.special_from_date,
    // special_to_date: ccProduct.special_to_date,
    contributo_raee: ccProduct?.c_raeeFee ? `${ccProduct.c_raeeFee}` : null,
  };
};

const listUserBaskets = async (customer_id) => {
  // const authorization = await getAuthHeader();
  const params = new URLSearchParams({
    siteId: SITE_ID,
  });
  const userBasketsUrl = `${BASE_URL}/customer/shopper-customers/v1/organizations/${ORGANIZATION_ID}/customers/${customer_id}/baskets?${params}`;
  const res = await fetchAuth(userBasketsUrl);
  if (!res.ok) {
    try {
      const data = await res.json();
      console.error("Error: ", data);
    } catch (err) {
      console.warn("Cannot read error data: ", err);
    }
    throw new Error(`Error ${res.status} ${res.statusText} - calling ${userBasketsUrl}`);
  }
  return res.json();
};

export const createUserBasket = async (userData) => {
  // const authorization = await getAuthHeader();
  const params = new URLSearchParams({
    siteId: SITE_ID,
  });
  const basketUrl = `${BASE_URL}/checkout/shopper-baskets/v1/organizations/${ORGANIZATION_ID}/baskets?${params.toString()}`;
  const res = await fetchAuth(basketUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      customerInfo: userData,
    }),
  });
  if (!res.ok) {
    try {
      const detailError = await res.json();
      console.error("Detail error: ", detailError);
    } catch (err) {}
    throw new Error(`Error create basket ${res.status} ${res.statusText}`);
  }
  const data = await res.json();
  return data;
};

const mergeBasket = async () => {
  const params = new URLSearchParams({
    siteId: SITE_ID,
  });
  const mergeUrl = `${BASE_URL}/checkout/shopper-baskets/v1/organizations/${ORGANIZATION_ID}/baskets/actions/merge?${params}`;
  const res = await fetchAuth(mergeUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });
  if (!res.ok) {
    throw new Error(`Error merge basket ${res.status} ${res.statusText}`);
  }
  return res.json();
};

let userBasketPromise;
const loadUserBasket = async (userData, guestCartId) => {
  const { baskets } = await listUserBaskets(userData.customerId);
  let basket = baskets?.[0];
  if (!baskets?.length) {
    basket = await createUserBasket({ email: userData.email });
  }
  if (guestCartId) {
    try {
      basket = await mergeBasket();
    } catch (err) {
      console.error("Error in merge basket: ", err);
    }
  }
  return normalizeCart(basket);
};
export const requestUserBasket = async (userData, guestCartId) => {
  if (!userBasketPromise) {
    userBasketPromise = loadUserBasket(userData, guestCartId);
    userBasketPromise.finally(() => {
      userBasketPromise = null;
    });
  }
  return userBasketPromise;
};

export const getCartUrl = () =>
  process.env.NODE_ENV === "development" && process.env.GATSBY_COMMERCE_CLOUD_CART
    ? process.env.GATSBY_COMMERCE_CLOUD_CART
    : withPrefix("/cart");

//#Source https://bit.ly/2neWfJ2
const splitChunks = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (_v, i) =>
    arr.slice(i * size, i * size + size)
  );

export const queryProductsDetails = async (productIds, currency) => {
  // const authorization = await getAuthHeader();
  const loadPaginated = async (productIds, size = 20) => {
    const chunks = splitChunks(productIds, size);
    const results = await Promise.all(
      chunks.map(async (chunk) => {
        const params = new URLSearchParams({
          siteId: SITE_ID,
          ids: chunk,
          locale: /^\/([a-zA-Z_]+)?\/?$/.exec(withPrefix("/"))?.[1]?.replace("_", "-") || "tr-TR", //TODO rimuovere in PROD
          currency,
          expand: "prices,availability,options",
          perPricebook: true,
        });
        const res = await fetchAuth(
          `/commerce-cloud-api/product/shopper-products/v1/organizations/${ORGANIZATION_ID}/products?${params.toString()}`
        );
        if (!res.ok) {
          let errorData;
          try {
            errorData = await res.json();
          } catch (e) {}
          if (
            errorData?.type ===
            "https://api.commercecloud.salesforce.com/documentation/error/v1/errors/response-entity-too-large"
          ) {
            const error = new Error(errorData.detail);
            error.type = "response-entity-too-large";
            throw error;
          }
          throw new Error(`Error getProducts ${res.status} ${res.statusText}`);
        }
        const data = await res.json();
        return data?.data ? data.data : [];
      })
    );
    return results;
  };
  let results;
  try {
    results = await loadPaginated(productIds, 10);
  } catch (err) {
    if (err.type === "response-entity-too-large") {
      results = await loadPaginated(productIds, 5);
    } else {
      throw err;
    }
  }
  return results.flat().map((item) => normalizeProduct(item));
};
